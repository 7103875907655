<template>
  <div :is="layout">
    <v-container fluid>
      <div v-if="info.length">
        <div v-for="(item,index) of info" :key="index">
        <v-data-table
        :headers="headers"
        hide-default-footer
        dense
        :items="item.detail"
        class="elevation-1"
      >
      </v-data-table>
      </div>
      </div>
      <div id="cesiumContainer"></div>
    </v-container>
     <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                       color="white"
                        text
                        outlined
                        v-on="on"
                        @click="boolean=!boolean"
                         style="position:absolute;left:30px;background:white;top:105px;"
                        class="ml-2"  x-small fab>
                        <i class="airport small-font icon-gantetu"
                        style='font-size: 18px !important; color: black '></i>
                    </v-btn>
                </template>
                <span>{{$t("flight.gantt")}}</span>
            </v-tooltip>
    <gantt v-show="boolean" @change="changeDate" :start_time="this.$route.params.start_time" :end_time="this.$route.params.end_time" :state="true" class="gantt"></gantt>
  </div>
</template>
<script>
import { getStopRoute,getScheduler } from "../../../api/airport_security";
import Gantt from '@/pages/airportSecurity/protectionVehicle/airportSecuritySCHPre.vue'
export default {
  name: "preview",
  components:{Gantt},
  data() {
    return {
      viewer: null,
      info: [],
      detail: [],
      flag: false,
      layout: null,
      list:null,
      clock:this.$route.params.start_time,
      boolean:false,
      current_date:null,
    };
  },
  mounted() {
    this.layout = this.$route.meta.use_layout
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
     changeDate(e){
      this.current_date=e;
    },
      formateDate(datetime) {
                function addDateZero(num) {
                    return num < 10 ? "0" + num : num;
                }

                let d = new Date(datetime);
                let formatdatetime =
                    d.getFullYear() +
                    "-" +
                    addDateZero(d.getMonth() + 1) +
                    "-" +
                    addDateZero(d.getDate()) +
                    " " +
                    addDateZero(d.getHours()) +
                    ":" +
                    addDateZero(d.getMinutes()) +
                    ":" +
                    addDateZero(d.getSeconds());
                return formatdatetime;
            },
    init() {
      // 飞机停靠路线预览
       this.viewer = new this.Cesium.Viewer("cesiumContainer", {
        infoBox: false,
        selectionIndicator: false,
        shadows: true,
        maximumRenderTimeChange: 1,
        shouldAnimate: true,
      });
      // 设置home默认位置
      this.Cesium.Camera.DEFAULT_VIEW_RECTANGLE =
        this.Cesium.Rectangle.fromDegrees(
          -122.3801087,
          37.62226498,
          -122.3801087,
          37.62226498
        ); //home定位到中国范围
      // 设置home默认视口大小
      this.Cesium.Camera.DEFAULT_VIEW_FACTOR = 0.0011;
      this.viewer.animation.viewModel.dateFormatter = (
        datetime,
        viewModel,
        ignoredate
      ) => {
        var julianDT = new this.Cesium.JulianDate();
        this.Cesium.JulianDate.addHours(datetime, 8, julianDT);
        var gregorianDT = this.Cesium.JulianDate.toGregorianDate(julianDT);
        var objDT;
        if (ignoredate) {
          objDT = "";
        } else {
          objDT = new Date(
            gregorianDT.year,
            gregorianDT.month - 1,
            gregorianDT.day
          );
          objDT =
            gregorianDT.year +
            "年" +
            objDT.toLocaleString("zh-cn", { month: "short" }) +
            gregorianDT.day +
            "日";
          if (viewModel || gregorianDT.hour + gregorianDT.minute === 0) {
            return objDT;
            objDT += "";
          }
        }
        return (
          objDT +
          this.Cesium.sprintf(
            "%02d:%02d:%02d",
            gregorianDT.hour,
            gregorianDT.minute,
            gregorianDT.second
          )
        );
      };

      getStopRoute(this.$route.params).then((res) => {
        const data = res.data;
        this.seat_conflict_data = res.seat_conflict_data;
        let times = [];
        data.forEach((item) => {
          item.path_info.forEach((item1, index) => {
            let location = [];
            let eachtime = [];
            item1.lines.forEach((ele, index1) => {
              // ------------距离
              if (index1 > 0) {
                let end = this.Cesium.Cartographic.fromDegrees(
                  ele.lng,
                  ele.lat
                );
                let start1 = this.Cesium.Cartographic.fromDegrees(
                  item1.lines[index1 - 1].lng,
                  item1.lines[index1 - 1].lat
                );
                let geodesic = new this.Cesium.EllipsoidGeodesic();
                geodesic.setEndPoints(start1, end);
                let distance = geodesic.surfaceDistance;
                location.push(distance);
              }
            });
            let speed = 12;
            location.reduce((pre, item) => {
              pre = pre + item / speed;
              eachtime.push(pre);
              return pre;
            }, 0);

            eachtime.unshift(0);
            times.push(eachtime);
          });
        });

        data.forEach((item) => {
          item.path_info.forEach((item1, index) => {
            if (index === 0) {
              item1.time = item.start_time;
              item1.totalTime =
                times[index][times[index].length - 1] + item1.await_time;
            } else {
              item1.totalTime =
                item.path_info[index - 1].totalTime +
                times[index][times[index].length - 1] +
                item1.await_time;
              item1.time = item.start_time + item.path_info[index - 1].totalTime;
            }
          });
        });
        this.viewer._cesiumWidget._creditContainer.style.display = "none";
        const start = this.Cesium.JulianDate.fromDate(new Date(new Date(this.$route.params.start_time).getTime() +8 * 60 * 60 * 1000));
        let duration =
          new Date(this.$route.params.end_time).getTime() -
          new Date(this.$route.params.start_time).getTime();
        duration = Math.round(duration / 1000);
        const stop = this.Cesium.JulianDate.addSeconds(
          start,
          duration,
          new this.Cesium.JulianDate()
        );
        this.viewer.clock.startTime = start.clone();
        // 设置时钟当前时间
        this.viewer.clock.currentTime = start.clone();
        // 设置时钟停止时间
        this.viewer.clock.stopTime = stop.clone();
        // 时间速率，数字越大时间过的越快
        this.viewer.clock.multiplier = 10;
        // 时间轴
        this.viewer.timeline.zoomTo(start, stop);
        // 循环执行,即为2，到达终止时间，重新从起点时间开始
        this.viewer.clock.clockRange = this.Cesium.ClockRange.LOOP_STOP;
        this.viewer.scene.globe.enableLighting = true;
        let position_path = [];
        data.forEach((item) => {
          const property = new this.Cesium.SampledPositionProperty();

          // const start2=this.Cesium.JulianDate.fromDate(new Date(ele.time));
          // 创建开始朱利安时间
          //   let _start_time = new this.Cesium.JulianDate.fromDate(new Date((item.time + 8 * 60 * 60) * 1000))

          item.path_info.forEach((item1, index) => {
            const property1 = new this.Cesium.SampledPositionProperty();
            // console.log((item1));
            let _start_time = new this.Cesium.JulianDate.fromDate(
              new Date((8 * 60 * 60 + item1.time) * 1000)
            );
            item1.lines.forEach((element) => {
              position_path.push(element.lng, element.lat);
            });
            item1.lines.forEach((ele, index1) => {
              const time = this.Cesium.JulianDate.addSeconds(
                _start_time,
                Math.round(times[index][index1]),
                new this.Cesium.JulianDate()
              );
              const position = this.Cesium.Cartesian3.fromDegrees(
                ele.lng,
                ele.lat,
                ele.height
              );
              property.addSample(time, position);
              property1.addSample(time, position);
            });

            const orientation = new this.Cesium.VelocityOrientationProperty(
              property
            );
            const orientation1 = new this.Cesium.VelocityOrientationProperty(
              property1
            );
             this.viewer.entities.add({
              id: `${item.car_id}-${item.airport_id}_${index}`,
              position: property1,
              availability: new this.Cesium.TimeIntervalCollection([
                new this.Cesium.TimeInterval({ start: start, stop: stop }),
              ]),
              orientation: orientation1,
              model: {
                show: true,
                uri: "https://cesium-1255710621.cos.ap-nanjing.myqcloud.com/CesiumMilkTruck.glb",
                minimumPixelSize: 64, //最小的模型像素
                runAnimations: true, //是否显示动画
                clampAnimations: true, //是否保持最后一针的动画
                color: this.Cesium.Color.WHITE, //颜色
              },
            });
            const customDataSource = new this.Cesium.CustomDataSource(
              "customDataSource"
            );
            customDataSource.entities.add(
              new this.Cesium.Entity({
                id: `${item.car_id}-${item.airport_id}`,
                position: property,
                // 由开始和停止时间定义的时间间隔
                availability: new this.Cesium.TimeIntervalCollection([
                  new this.Cesium.TimeInterval({ start: start, stop: stop }),
                ]),
                // 根据所提供的速度计算模型的朝向
                orientation: orientation,
                model: new this.Cesium.ModelGraphics({
                  show: true,
                  uri: "https://cesium-1255710621.cos.ap-nanjing.myqcloud.com/CesiumMilkTruck.glb",
                  minimumPixelSize: 64, //最小的模型像素
                  maximumScale: 128, //最大的模型像素
                  runAnimations: true, //是否显示动画
                  clampAnimations: true, //是否保持最后一针的动画
                  color: this.Cesium.Color.RED, //颜色
                }),
                path: {
                  position: property,
                  show: true,
                  leadTime: 60,
                  trailTime: 60,
                  width: 10,
                  resolution: 1,
                  material: new this.Cesium.PolylineGlowMaterialProperty({
                    glowPower: 0.3,
                    color: this.Cesium.Color.PALEGOLDENROD,
                  }),
                },
              })
            );

            this.viewer.dataSources.add(customDataSource);

            //设置摄像头定位到模型处
            this.viewer.camera.flyTo({
              destination: this.Cesium.Cartesian3.fromDegrees(
                -122.3801087,
                37.62226498,
                7500.0
              ), // 洛杉矶机场
            });
          });
          this.viewer.entities.add({
            id: `${item.car_id}-${item.airport_id}`,
            show: false,
            polyline: {
              positions: this.Cesium.Cartesian3.fromDegreesArray(position_path),
              width: 1,
              material: this.Cesium.Color.BLUE,
            },
          });
        });
        const handler = new this.Cesium.ScreenSpaceEventHandler(
          this.viewer.scene.canvas
        );
        let fun = (item1) => {
           this.detail = [];
          item1.forEach(item=>{
            item.end_point_list.forEach((ele, index) => {
              this.detail.push({name:item.car_id,airport_id:item.airport_id,end_point:ele.end_point, waitTime: ele.await_time,start_time:this.formateDate(item.start_time*1000)});
          });
        })
          this.info.push({detail: this.detail})
        };
       // 取消双击默认事件
      this.viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
        this.Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );
        handler.setInputAction((movement) => {
          const pick = this.viewer.scene.pick(movement.position);
          if (pick) {
            let total_id = pick.id.id.split("_")[0];
            let id=total_id.split('-')[0]
            let airport_id=total_id.split('-')[1]
            // var boolean = pick.id.polyline.show._value;
            // pick.id.polyline.show =!boolean;
            var boolean=this.viewer.entities.getById(total_id).show
            this.viewer.entities.getById(total_id).show=!boolean
            if (!boolean) {
              getScheduler({car_id:id,airport_id:airport_id}).then(res => {
                    this.list=res.results;
                    if (this.info.length) {
                  let index=this.info.filter((ele) => ele.detail[0].name == id&&ele.detail[0].airport_id==airport_id);
                  if (index.length==0) fun(data[i]);
                } else fun(this.list);
                })
            }
             else {
                let check_data;
                let index1;
                this.info.filter((ele,index) => {
                if(ele.detail[0].name == id&&ele.detail[0].airport_id==airport_id){
                 check_data=ele;
                 index1=index;
                }
              });
              if (check_data) {
                this.info.splice(index1, 1);
              }
            }
          }
        }, this.Cesium.ScreenSpaceEventType.LEFT_CLICK);
      })
    },
  },
    watch:{
          current_date: {
                handler: function(val) {
                    this.clock =  this.Cesium.JulianDate.fromDate(
                     new Date(new Date(val.split('_')[0]).getTime() +8 * 60 * 60 * 1000)
                        );
                this.viewer.clock.currentTime = this.clock;
                },
            },
  },
  computed: {
            headers() {
                return [
                    {
                        text: this.$t('airport_security.number'),
                        align: 'center',
                        value: 'name',
                        sortable: false
                    },
                    {
                        text: this.$t('airport_security.start_time'),
                        align: 'center',
                        value: 'start_time',
                        sortable: false
                    },
                       {
                        text: this.$t('airport_security.point'),
                        align: 'center',
                        value: 'end_point',
                        sortable: false
                    },
                      {
                        text: this.$t('airport_security.await_time'),
                        align: 'center',
                        value: 'waitTime',
                        sortable: false
                    },
                ]
            }
        }
};
</script>

<style lang="stylus" scoped>
#cesiumContainer
    height calc(100vh - 150px)
.elevation-1{
  width:500px;
  background-color: rgba(255, 255, 255, 0.8) !important;
  position: absolute;
  top:50px;
  left: 10px;
  z-index: 1;
}
.box_info {
  width: 300px;
  color: black;
  position: absolute;
  top: 80px;
  left: 50px;
  z-index: 1;
  border-radius: 3px;
  font-size: 13px;
  padding: 20px;
}
.box{
    background: rgba(255, 255, 255, 0.8);
    padding:10px;
    margin-bottom: 10px;
}
.gantt{
  position:absolute;
  background:rgba(255,255,255,0.5);
  width:800px;
  height:80vh;
  z-index:1;
  top: 100px;
  right: 30px;
  overflow:auto
}

>>> #gantt_here
    .gantt_scale_cell
        margin-left: -35px;
.gantt /deep/ #gantt
    background rgba(255,255,255,0.3) !important
    color:#444
.gantt /deep/ #gantt
.gantt /deep/ #gantt_here
  .gantt_data_area, .gantt_task_cell, .gantt_task_row, .gantt_grid_scale, .gantt_container, .gantt_task_row.gantt_selected, .gantt_task_scale, .gantt_grid_data, .gantt_row gantt_row_task, .gantt_row, .gantt_row_task, .gantt_grid_scale *
     background rgba(255,255,255,0.3) !important
  & *
     color #444 !important
     border-color #444 !important
</style>
